import axios from 'axios';
import { io, Socket } from 'socket.io-client';
import User from './User';
import Stream from './Stream';
import State from './State';
import { screen, streamScreen } from '../types/enums';

const STREAM_URL = process.env.REACT_APP_DEV ? 'http://localhost:4000' : process.env.REACT_APP_STREAM;

class Sockets {
  constructor() {
    this._init();
  }
  
  private _socket: Socket = null;

  private _init(): void {
    this._connect();
  }

  private _connect(): void {
    if (this._socket === null) {
      this._socket = io(STREAM_URL + '?id=' + User.getUser().id);
      this._streamState();
    }
  }

  private _streamState(): void {
    this._socket.on('stream', (data: IstreamState) => {
      if (State.getScreen() === screen.STREAM && Stream.getScreen() === streamScreen.FINAL_RATINGS && !data.ready) {
        Stream.setTutorial(true);
        State.setScreen(screen.MAIN);
      }

      if (data.screen !== Stream.getScreen()) {
        Stream.setCorrect(null);
        Stream.setAnswer(null);
        Stream.setDisable(false);
      }

      if (data.question?.id !== Stream.getQuestion()?.id) {
        Stream.setCorrect(null);
        Stream.setAnswer(null);
        Stream.setDisable(false);
      }

      if (data.screen !== streamScreen.FINAL_RATINGS && data.screen !== streamScreen.FIRST_RATINGS && (Stream.getPlace() !== '-' || Stream.getScore() !== '-')) {
        Stream.setPlace(null);
        Stream.setScore(null);
      } else if ((data.screen === streamScreen.FINAL_RATINGS || data.screen === streamScreen.FIRST_RATINGS) && (Stream.getPlace() === '-' || Stream.getScore() === '-')) {
        this._getPersonalRating();
      }

      if ((data.screen === streamScreen.PRE_STREAM || data.screen === streamScreen.PRE_FINAL) && data.timer === 13) {
        State.playSound('streamTimer');
      }

      Stream.setReady(data.ready);
      Stream.setScreen(data.screen);
      Stream.setTimer(data.timer);
      Stream.setQuestion(data.question);
      Stream.setRatings(data.ratings);
    });
  }

  private _getPersonalRating(): void {
    if (Stream.isDisable()) return;
    Stream.setDisable(true);
    axios.post(STREAM_URL + '/api/getPlacePoints', {
      id: User.getUser().id
    }).then(res => {
      Stream.setPlace(res.data.place);
      Stream.setScore(res.data.score);
      Stream.setDisable(false);
    }).catch(e => {
      Stream.setCorrect(null);
      Stream.setAnswer(null);
      Stream.setDisable(false);
    });
  }
}

export default Sockets;