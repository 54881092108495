import { makeAutoObservable, runInAction } from 'mobx';
import { error, screen } from '../types/enums';

class State {
  constructor() {
    makeAutoObservable(this);
    setInterval((): void => {
      runInAction((): void => {
        if (this._superQuestion) {
          this._superQuestion.start--;
          this._superQuestion.end--;
        }
      });
    }, 1000);
  }

  public sound: HTMLAudioElement;
  public readonly MAX_QUESTIONS_1_ROUND = 10;
  public readonly IS_ODR = process.env.REACT_APP_ODR === 'true' ? true : false;
  private _screen: screen = screen.LOADING;
  private _loading: number = 0;
  private _error: error = null;
  private _superQuestion: IsuperQuestion;
  private _interactive: boolean = false;
  private _loaded: boolean = false;
  public sounds = {
    button: null,
    rules1: null,
    rules2: null,
    rightAnswer: null,
    wrongAnswer: null,
    mainSound: null,
    round3: null,
    timer: null
  }
  private _ratingCopyright: string;
  private _resultCopyright: string;
  private _questionCopyright1: string;
  private _questionCopyright2: string;
  private _questionTime: string;
  private _notifTutorial: boolean = false;
  private _notifications: boolean = null;

  public getScreen(): screen {
    return this._screen;
  }

  public setScreen(page: screen): void {
    if (page !== screen.RATINGS) {
      this.sound?.pause();
      this.sound && (this.sound.loop = false);
    }
    this._screen = page;
  }

  public getLoading(): number {
    return this._loading;
  }

  public setLoading(loading: number): void {
    this._loading = loading;
  }

  public getError(): error {
    return this._error;
  }

  public setError(error: error): void {
    this._error = error;
  }

  public getSuperQuestion(): IsuperQuestion {
    return this._superQuestion;
  }

  public setSuperQuestion(question: IsuperQuestion): void {
    this._superQuestion = question;
  }

  public getInteractive(): boolean {
    return this._interactive;
  }

  public setInteractive(): void {
    this._interactive = true;
  }

  public playSound(key: string): void {
    const audio = this.sounds[key]
    audio.currentTime = 0;
    audio.play();
  }

  public getLoaded(): boolean {
    return this._loaded;
  }

  public setLoaded(loaded: boolean): void {
    this._loaded = loaded;
  }

  public getRatingCopyright(): string {
    return this._ratingCopyright;
  }

  public setRatingCopyright(copyright: string): void {
    this._ratingCopyright = copyright;
  }

  public getResultCopyright(): string {
    return this._resultCopyright;
  }

  public setResultCopyright(copyright: string): void {
    this._resultCopyright = copyright;
  }

  public getQuestionCopyright1(): string {
    return this._questionCopyright1;
  }

  public setQuestionCopyright1(copyright: string): void {
    this._questionCopyright1 = copyright;
  }

  public getQuestionCopyright2(): string {
    return this._questionCopyright2;
  }

  public setQuestionCopyright2(copyright: string): void {
    this._questionCopyright2 = copyright;
  }

  public getQuestionTime(): string {
    return this._questionTime;
  }

  public setQuestionTime(copyright: string): void {
    this._questionTime = copyright;
  }

  public setNoifTitorial(): void {
    this._notifTutorial = true;
  }

  public isShowedNotifTutorial(): boolean {
    return this._notifTutorial;
  }

  public setNotifications(notifications: boolean): void {
    this._notifications = notifications;
  }

  public isNotifications(): boolean {
    return this._notifications;
  }
}

export default new State();