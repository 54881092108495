import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import bridge from '@vkontakte/vk-bridge';
import State from './data/State';
import Loading from './screens/Loading';
import Error from './screens/Error';
import Main from './screens/Main';
import Rules from './screens/Rules';
import Ready from './screens/Ready';
import Round1 from './screens/Round1';
import Round2 from './screens/Round2';
import Round3 from './screens/Round3';
import Result from './screens/Result';
import Ratings from './screens/Ratings';
import Question from './screens/Question';
import Stream from './screens/Stream';
import Prize from './screens/Prize';
import { screen } from './types/enums';

export default observer(() => {
  const [vertical, setVertical] = useState(window.innerWidth > window.innerHeight);
  useEffect(() => {
    window.addEventListener('resize', () => {
      if (![screen.SUPER_QUESTION, screen.ROUND_3].find(data => data === State.getScreen())) {
        setVertical(window.innerWidth > window.innerHeight);
      }
    });
    bridge.subscribe(e => {
      if (e.detail.type === 'VKWebAppViewHide') {
        if ([screen.MAIN, screen.RATINGS].find(data => data === State.getScreen()) && !State.sound.paused) {
          State.sound.pause();
        }
      } else if (e.detail.type === 'VKWebAppViewRestore') {
        if ([screen.MAIN, screen.RATINGS].find(data => data === State.getScreen()) && State.sound.paused) {
          State.sound.play();
        }
      } else if (e.detail.type === 'VKWebAppAllowNotificationsResult') {
        e.detail.data.result && State.setNotifications(true);
      } else if (e.detail.type === 'VKWebAppDenyNotificationsResult') {
        e.detail.data.result && State.setNotifications(false);
      }
    });
  }, []);

  if (vertical) {
    return <div style={{
      color: '#fFFFFF',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      fontSize: 34,
      padding: 20,
      textAlign: 'center'
    }}>Поверните устройство в вертикальное положение</div>
  } else {
    return State.getScreen() === screen.LOADING ? <Loading /> :
      State.getScreen() === screen.ERROR ? <Error /> :
      State.getScreen() === screen.MAIN ? <Main /> :
      State.getScreen() === screen.RULES ? <Rules /> :
      State.getScreen() === screen.READY ? <Ready /> :
      State.getScreen() === screen.ROUND_1 ? <Round1 /> :
      State.getScreen() === screen.ROUND_2 ? <Round2 /> :
      State.getScreen() === screen.ROUND_3 ? <Round3 /> :
      State.getScreen() === screen.RESULT ? <Result /> :
      State.getScreen() === screen.RATINGS ? <Ratings /> :
      State.getScreen() === screen.SUPER_QUESTION ? <Question /> :
      State.getScreen() === screen.STREAM ? <Stream /> :
      State.getScreen() === screen.PRIZE ? <Prize /> :
      null;
  }
});